<template>
  <li :class="{ 'timeline-inverted': inverted }">
    <slot name="badge">
      <div class="timeline-badge">
        <img :src="badgeIcon" alt="" />
      </div>
    </slot>
    <div class="timeline-panel">
      <div class="timeline-heading">
        <slot name="header"></slot>
      </div>
      <div class="timeline-body" v-if="$slots.content">
        <slot name="content"></slot>
      </div>
      <div v-if="$slots.footer" class="timeline-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </li>
</template>
<script>
export default {
  name: 'time-line-item',
  props: {
    inverted: Boolean,
    badgeType: {
      type: String,
      default: 'success'
    },
    badgeIcon: {
      type: String,
      default: ''
    }
  }
}
</script>
<style>
.timeline-badge {
  overflow: hidden;
  border: 1px solid black;
}

.timeline-badge img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
}
</style>
